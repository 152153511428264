// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.nes.iperdesign.it/api/services/', // Andrea
  documentUrl: 'https://api.nes.iperdesign.it/api/services/', // Andrea
  //apiUrl: 'http://192.168.150.36:8090/api/services/', // Me
  //documentUrl: 'http://192.168.150.26:8089/api/services/', // Me
  eventKey: 28,
};
